/**
 * 创建文本类型SVG标签
 */
export const createSvgTag = (editableTabsValue, v, tag, key, val) => {
    // 给v节点text元素添加class属性
    v.attr('text/class', 't'+v.id)
    // 通过class属性找到对应的元素标签
    const container = document.getElementById('containerDetailChart' + editableTabsValue)
    const tagContent = container.querySelector('.t' + v.id)
    // 获取文本父节点
    const parentNode = tagContent.parentNode
    // 获取父节点下的rect元素
    const getRect = parentNode.querySelector('rect')
    let rectWidth = getRect.getBoundingClientRect().width
    let rectHeight = getRect.getBoundingClientRect().height
    // 创建svg和xhtml元素标签
    let svgNS = 'http://www.w3.org/2000/svg';
    let xhtml = 'http://www.w3.org/1999/xhtml';
    let foreignObject = document.createElementNS(svgNS, 'foreignObject')
    let body = document.createElementNS(xhtml, 'body')
    let p = document.createElement('p')
    let span1 = document.createElement('span')
    let span2 = document.createElement('span')
    let span3 = document.createElement('span')
    const flag = navigator.userAgent.match( // match方法可在字符串内检索指定的值，
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
    if (flag) {
        // 将foreignObject标签宽高和rect宽高相统一
        foreignObject.setAttribute('width', rectWidth*2.5)
        foreignObject.setAttribute('height', rectHeight)
    } else {
        // 将foreignObject标签宽高和rect宽高相统一
        foreignObject.setAttribute('width', rectWidth)
        foreignObject.setAttribute('height', rectHeight)
    }
    // 处理p标签
    p.style.margin = '0'
    p.style.textAlign = 'center'
    p.style.fontSize = v.attrs.label.fontSize+'px'
    if (flag) {
        p.style.height = rectHeight + 'px'
    } else {
        p.style.height = rectHeight + 'px'
    }
    p.style.lineHeight = rectHeight + 'px'
    p.style.display = 'flex'
    // p.style.justifyContent = 'space-around'
    p.style.alignItems = 'center'
    p.style.fontFamily = 'SourceHanSerifCN-Bold'
    // 处理span标签
    span1.style.color = v.attrs.label.fill ? v.attrs.label.fill : 'rgba(248, 245, 63, 1)'
    span1.innerText = v.attrs.label.text

    span2.style.color = v.attrs.label.DynamicTextColor ? v.attrs.label.DynamicTextColor : '#eb3b5a'
    span2.style.marginLeft = '5px'
    span2.style.marginRight = '5px'
    // if(v.attrs.label.DynamicTextColor) {
    //     span2.style.borderBottom = `1px solid ${v.attrs.label.DynamicTextColor}`
    // }
    // span2.style.fontWeight = 'bold'
    span2.innerText = key

    span3.style.color = v.attrs.label.fill ? v.attrs.label.fill : 'rgba(248, 245, 63, 1)'
    span3.innerText = val?val:''

    v.attr({
        label: {
            style: {
                display: 'none'
            }
        }
    })
    // 将子元素追加至父元素
    p.appendChild(span1)
    p.appendChild(span2)
    p.appendChild(span3)
    body.appendChild(p)
    foreignObject.appendChild(body)
    return foreignObject
}