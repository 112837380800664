import { waterLinearAnmi } from './waterLinearAnimation'
import { waterLevel } from './waterLevelAnimation'
import { createWaterSvg } from './waterLevelSvg'
import { createSvgTag } from './textTypeSvg'
import { editSvgTag } from './editTextSvg'
import { imgRotateAnmi } from './rotateAnimation'
/**
 * 处理设备节点
 */
export const reportCache = (graph,wsdata,editableTabsValue) => {
    // 获取所有画布上节点
    let allNodes = graph.getNodes()
    //循环画布上所有节点
    allNodes.forEach(v=>{
      if(v.store.data.data&&JSON.stringify(v.store.data.data.graphical)=='{}') {
        // v.attr('body/fill', 'none')
      }
      v.attr('text/fontFamily', 'SourceHanSerifCN-Bold')
      if(v.store.data.data&&v.store.data.data.withData==0) {
        //判断是否是同一设备
        if(v.store.data.data&&wsdata.eid==v.store.data.data.eid) {
          let operationArr = []
          //循环websocket推送Tag数据
          for(let item in wsdata.content){
            //判断节点类型为image
            if(v.store.data.shape=='image') {
              if(v.store.data.data.graphical.customAnimations) {
                v.store.data.data.graphical.customAnimations.forEach(anV=>{
                  if(anV.animation=='textChange') {//文本变化
                    anV.textChange.dataParameters.forEach(textCV=>{
                      if(textCV.dataType=='Bool') {
                        if(textCV.boolData.formulaType=='empty') {
                          if(item == textCV.boolData.tag) {
                            let itemVal = Number(wsdata.content[item]).toFixed(2)
                            let numTagVal= Number(textCV.boolData.tagValue)
                            let numItemVal= Number(itemVal)
                            if(numItemVal==numTagVal) {
                              if(textCV.display=='hide') {
                                v.attr('image/class', 'hideNode')
                              }
                              if(textCV.display=='keep') {
                                v.attr('image/class', 'showNode')
                              }
                            }
                          }
                        }
                        if(textCV.boolData.formulaType=='&') {
                          let tagArr = textCV.boolData.tag.split('&')
                          tagArr.forEach((tag,tagIndex)=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              let numItemVal= Number(itemVal)
                              operationArr.push(numItemVal)
                              if(tagArr.length-1==tagIndex) {
                                let opResult = operationArr.every(op => { return op == textCV.boolData.tagValue})
                                if(opResult) {
                                  if(textCV.display=='hide') {
                                    v.attr('image/class', 'hideNode')
                                  }
                                  if(textCV.display=='keep') {
                                    v.attr('image/class', 'showNode')
                                  }
                                  return
                                }
                              }
                            }
                          })
                        }
                        if(textCV.boolData.formulaType=='||') {
                          let tagArr = textCV.boolData.tag.split('||')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              let numItemVal= Number(itemVal)
                              if(numItemVal==textCV.boolData.tagValue) {
                                // console.log(v)
                                if(textCV.display=='hide') {
                                  v.attr('image/class', 'hideNode')
                                }
                                if(textCV.display=='keep') {
                                  v.attr('image/class', 'showNode')
                                }
                                return
                              }
                            }
                          })
                        }
                        if(textCV.boolData.formulaType=='!') {

                        }
                      }
                      if(textCV.dataType=='Real') {
                        if(textCV.realData.formulaType=='empty') {
                          if(item == textCV.realData.tag) {
                            if(textCV.display=='hide') {
                              v.attr('image/class', 'hideNode')
                            }
                            if(textCV.display=='keep') {
                              v.attr('image/class', 'showNode')
                            }
                          }
                        }
                        if(textCV.realData.formulaType=='+') {
                          let tagArr = textCV.realData.tag.split('+')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              if(textCV.display=='hide') {
                                v.attr('image/class', 'hideNode')
                              }
                              if(textCV.display=='keep') {
                                v.attr('image/class', 'showNode')
                              }
                            }
                          })
                        }
                        if(textCV.realData.formulaType=='-') {
                          let tagArr = textCV.realData.tag.split('-')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              if(textCV.display=='hide') {
                                v.attr('image/class', 'hideNode')
                              }
                              if(textCV.display=='keep') {
                                v.attr('image/class', 'showNode')
                              }
                            }
                          })
                        }
                      }
                    })
                  }
                  if(anV.animation=='background') {//背景图片变化
                    anV.background.dataParameters.forEach(textCV=>{
                      if(textCV.dataType=='Bool') {
                        if(textCV.boolData.formulaType=='empty') {
                          if(!isNaN(wsdata.content[item])) { //字符串为数字
                            if(item == textCV.boolData.tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              let numTagVal= Number(textCV.boolData.tagValue)
                              let numItemVal= Number(itemVal)
                              if(numItemVal==numTagVal) {
                                if(textCV.backgroundType=='file') {
                                  v.attr('image/href', textCV.backgroundFilePath)
                                }
                              }
                            }
                          }else {
                            if(item == textCV.boolData.tag) {
                              if(wsdata.content[item]==textCV.boolData.tagValue) {
                                if(textCV.backgroundType=='file') {
                                  v.attr('image/href', textCV.backgroundFilePath)
                                }
                                return
                              }
                            }
                          }
                        }
                        if(textCV.boolData.formulaType=='&') {
                          let tagArr = textCV.boolData.tag.split('&')
                          tagArr.forEach((tag,tagIndex)=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              let numItemVal= Number(itemVal)
                              operationArr.push(numItemVal)
                              if(tagArr.length-1==tagIndex) {
                                let opResult = operationArr.every(op => { return op == textCV.boolData.tagValue})
                                if(opResult) {
                                  if(textCV.backgroundType=='file') {
                                    v.attr('image/href', textCV.backgroundFilePath)
                                  }
                                  return
                                }
                              }
                            }
                          })
                        }
                        if(textCV.boolData.formulaType=='||') {
                          let tagArr = textCV.boolData.tag.split('||')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              let numItemVal= Number(itemVal)
                              if(numItemVal==textCV.boolData.tagValue) {
                                if(textCV.backgroundType=='file') {
                                  v.attr('image/href', textCV.backgroundFilePath)
                                }
                                return
                              }
                            }
                          })
                        }
                        if(textCV.boolData.formulaType=='!') {
                          if(!isNaN(wsdata.content[item])) { //字符串为数字
                            let tagArr = textCV.boolData.tag.split('!')
                            tagArr.forEach(tag=>{
                              if(item==tag) {
                                let itemVal = Number(wsdata.content[item]).toFixed(2)
                                let numItemVal= Number(itemVal)
                                if(numItemVal==textCV.boolData.tagValue) {
                                  if(textCV.backgroundType=='file') {
                                    v.attr('image/href', textCV.backgroundFilePath)
                                  }
                                  return
                                }
                              }
                            })
                          }else {
                            let tagArr = textCV.boolData.tag.split('!')
                            tagArr.forEach(tag=>{
                              if(item==tag) {
                                if(wsdata.content[item]!=textCV.boolData.tagValue) {
                                  if(textCV.backgroundType=='file') {
                                    v.attr('image/href', textCV.backgroundFilePath)
                                  }
                                  return
                                }
                              }
                            })
                          }
                        }
                      }
                      if(textCV.dataType=='Real') {
                        if(textCV.realData.formulaType=='empty') {
                          if(item == textCV.realData.tag) {
                            let itemVal = Number(wsdata.content[item]).toFixed(2)
                            if(textCV.backgroundType=='file') {
                              v.attr('image/href', textCV.backgroundFilePath)
                            }
                          }
                        }
                        if(textCV.realData.formulaType=='+') {
                          let tagArr = textCV.realData.tag.split('+')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              if(textCV.backgroundType=='file') {
                                v.attr('image/href', textCV.backgroundFilePath)
                              }
                            }
                          })
                        }
                        if(textCV.realData.formulaType=='-') {
                          let tagArr = textCV.realData.tag.split('-')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              if(textCV.backgroundType=='file') {
                                v.attr('image/href', textCV.backgroundFilePath)
                              }
                            }
                          })
                        }
                      }
                    })
                  }
                  if(anV.animation=='rotate') {//旋转动画
                    if(anV.imgRotate.startParameter){
                      if(anV.imgRotate.startParameter.dataType=='Bool') {
                        if(anV.imgRotate.startParameter.boolData.formulaType=='empty') {
                          if(item == anV.imgRotate.startParameter.boolData.tag) {
                            let itemVal = Number(wsdata.content[item]).toFixed(2)
                            let numTagVal= Number(anV.imgRotate.startParameter.boolData.tagValue)
                            let numItemVal= Number(itemVal)
                            if(numItemVal==numTagVal) {
                              imgRotateAnmi(v,wsdata.content)
                            }
                          }
                        }
                        if(anV.imgRotate.startParameter.boolData.formulaType=='&') {
                          let tagArr = anV.imgRotate.startParameter.boolData.tag.split('&')
                          tagArr.forEach((tag,tagIndex)=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              let numItemVal= Number(itemVal)
                              operationArr.push(numItemVal)
                              if(tagArr.length-1==tagIndex) {
                                let opResult = operationArr.every(op => { return op == anV.imgRotate.startParameter.boolData.tagValue})
                                if(opResult) {
                                  imgRotateAnmi(v,wsdata.content)
                                  return
                                }
                              }
                            }
                          })
                        }
                        if(anV.imgRotate.startParameter.boolData.formulaType=='||') {
                          let tagArr = anV.imgRotate.startParameter.boolData.tag.split('||')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              let numItemVal= Number(itemVal)
                              if(numItemVal==anV.imgRotate.startParameter.boolData.tagValue) {
                                imgRotateAnmi(v,wsdata.content)
                                return
                              }
                            }
                          })
                        }
                        if(anV.imgRotate.startParameter.boolData.formulaType=='!') {

                        }
                      }
                      if(anV.imgRotate.startParameter.dataType=='Real') {
                        if(anV.imgRotate.startParameter.realData.formulaType=='empty') {
                          if(item == anV.imgRotate.startParameter.realData.tag) {
                            imgRotateAnmi(v,wsdata.content)
                          }
                        }
                        if(anV.imgRotate.startParameter.realData.formulaType=='+') {
                          let tagArr = anV.imgRotate.startParameter.realData.tag.split('+')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              imgRotateAnmi(v,wsdata.content)
                            }
                          })
                        }
                        if(anV.imgRotate.startParameter.realData.formulaType=='-') {
                          let tagArr = anV.imgRotate.startParameter.realData.tag.split('-')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              imgRotateAnmi(v,wsdata.content)
                            }
                          })
                        }
                      }
                    }
                    
                    if(anV.imgRotate.stopParameter){
                      if(anV.imgRotate.stopParameter.dataType=='Bool') {
                        if(anV.imgRotate.stopParameter.boolData.formulaType=='empty') {
                          if(item == anV.imgRotate.stopParameter.boolData.tag) {
                            let itemVal = Number(wsdata.content[item]).toFixed(2)
                            let numTagVal= Number(anV.imgRotate.stopParameter.boolData.tagValue)
                            let numItemVal= Number(itemVal)
                            if(numItemVal==numTagVal) {
                              if(anV.imgRotate.stopParameter.display=='hide') {
                                v.attr('image/style', 'animation-play-state:paused;')
                                v.attr('image/class', 'hideNode')
                              }
                              if(anV.imgRotate.stopParameter.display=='keep') {
                                v.attr('image/style', 'animation-play-state:paused;')
                                v.attr('image/class', 'showNode')
                              }
                            }
                          }
                        }
                        if(anV.imgRotate.stopParameter.boolData.formulaType=='&') {
                          let tagArr = anV.imgRotate.stopParameter.boolData.tag.split('&')
                          tagArr.forEach((tag,tagIndex)=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              let numItemVal= Number(itemVal)
                              operationArr.push(numItemVal)
                              if(tagArr.length-1==tagIndex) {
                                let opResult = operationArr.every(op => { return op == anV.imgRotate.stopParameter.boolData.tagValue})
                                if(opResult) {
                                  if(anV.imgRotate.stopParameter.display=='hide') {
                                    v.attr('image/style', 'animation-play-state:paused;')
                                    v.attr('image/class', 'hideNode')
                                  }
                                  if(anV.imgRotate.stopParameter.display=='keep') {
                                    v.attr('image/style', 'animation-play-state:paused;')
                                    v.attr('image/class', 'showNode')
                                  }
                                  return
                                }
                              }
                            }
                          })
                        }
                        if(anV.imgRotate.stopParameter.boolData.formulaType=='||') {
                          let tagArr = anV.imgRotate.stopParameter.boolData.tag.split('||')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              let numItemVal= Number(itemVal)
                              if(numItemVal==anV.imgRotate.stopParameter.boolData.tagValue) {
                                if(anV.imgRotate.stopParameter.display=='hide') {
                                  v.attr('image/style', 'animation-play-state:paused;')
                                  v.attr('image/class', 'hideNode')
                                }
                                if(anV.imgRotate.stopParameter.display=='keep') {
                                  v.attr('image/style', 'animation-play-state:paused;')
                                  v.attr('image/class', 'showNode')
                                }
                                return
                              }
                            }
                          })
                        }
                        if(anV.imgRotate.stopParameter.boolData.formulaType=='!') {

                        }
                      }
                      if(anV.imgRotate.stopParameter.dataType=='Real') {
                        if(anV.imgRotate.stopParameter.realData.formulaType=='empty') {
                          if(item == anV.imgRotate.stopParameter.realData.tag) {
                            if(anV.imgRotate.stopParameter.display=='hide') {
                              v.attr('image/style', 'animation-play-state:paused;')
                              v.attr('image/class', 'hideNode')
                            }
                            if(anV.imgRotate.stopParameter.display=='keep') {
                              v.attr('image/style', 'animation-play-state:paused;')
                              v.attr('image/class', 'showNode')
                            }
                          }
                        }
                        if(anV.imgRotate.stopParameter.realData.formulaType=='+') {
                          let tagArr = anV.imgRotate.stopParameter.realData.tag.split('+')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              if(anV.imgRotate.stopParameter.display=='hide') {
                                v.attr('image/style', 'animation-play-state:paused;')
                                v.attr('image/class', 'hideNode')
                              }
                              if(anV.imgRotate.stopParameter.display=='keep') {
                                v.attr('image/style', 'animation-play-state:paused;')
                                v.attr('image/class', 'showNode')
                              }
                            }
                          })
                        }
                        if(anV.imgRotate.stopParameter.realData.formulaType=='-') {
                          let tagArr = anV.imgRotate.stopParameter.realData.tag.split('-')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              if(anV.imgRotate.stopParameter.display=='hide') {
                                v.attr('image/style', 'animation-play-state:paused;')
                                v.attr('image/class', 'hideNode')
                              }
                              if(anV.imgRotate.stopParameter.display=='keep') {
                                v.attr('image/style', 'animation-play-state:paused;')
                                v.attr('image/class', 'showNode')
                              }
                            }
                          })
                        }
                      }
                    }
                  }
                })
              }
            }else {//普通节点
              if(v.store.data.data.graphical.customAnimations) {//节点customAnimations数据存在
                v.store.data.data.graphical.customAnimations.forEach(anV=>{
                  if(anV.animation=='Empty') {//无动画
                    if(anV.emptyAnimation.dataType=='Bool') {
                      if(anV.emptyAnimation.boolData.formulaType=='empty') {
                        if(item == anV.emptyAnimation.boolData.tag) {
                          let itemVal = Number(wsdata.content[item]).toFixed(2)
                          let numTagVal= Number(anV.emptyAnimation.boolData.tagValue)
                          let numItemVal= Number(itemVal)
                          if(numItemVal==numTagVal) {
                            v.attr('svgele/style', 'animation-play-state:paused;')
                            v.attr('svgele/class', 'hideNode')
                          }
                        }
                      }
                      if(anV.emptyAnimation.boolData.formulaType=='&') {
                        let tagArr = anV.emptyAnimation.boolData.tag.split('&')
                        tagArr.forEach((tag,tagIndex)=>{
                          if(item==tag) {
                            let itemVal = Number(wsdata.content[item]).toFixed(2)
                            let numItemVal= Number(itemVal)
                            operationArr.push(numItemVal)
                            if(tagArr.length-1==tagIndex) {
                              let opResult = operationArr.every(op => { return op == anV.emptyAnimation.boolData.tagValue})
                              if(opResult) {
                                v.attr('svgele/style', 'animation-play-state:paused;')
                                v.attr('svgele/class', 'hideNode')
                                return
                              }
                            }
                          }
                        })
                      }
                      if(anV.emptyAnimation.boolData.formulaType=='||') {
                        let tagArr = anV.emptyAnimation.boolData.tag.split('||')
                        tagArr.forEach(tag=>{
                          if(item==tag) {
                            let itemVal = Number(wsdata.content[item]).toFixed(2)
                            let numItemVal= Number(itemVal)
                            if(numItemVal==anV.emptyAnimation.boolData.tagValue) {
                              v.attr('svgele/style', 'animation-play-state:paused;')
                              v.attr('svgele/class', 'hideNode')
                              return
                            }
                          }
                        })
                      }
                      if(anV.emptyAnimation.boolData.formulaType=='!') {

                      }
                    }
                    if(anV.emptyAnimation.dataType=='Real') {
                      if(anV.emptyAnimation.realData.formulaType=='empty') {
                        if(item == anV.emptyAnimation.realData.tag) {
                          v.attr('svgele/style', 'animation-play-state:paused;')
                          v.attr('svgele/class', 'hideNode')
                        }
                      }
                      if(anV.emptyAnimation.realData.formulaType=='+') {
                        let tagArr = anV.emptyAnimation.realData.tag.split('+')
                        tagArr.forEach(tag=>{
                          if(item==tag) {
                            v.attr('svgele/style', 'animation-play-state:paused;')
                            v.attr('svgele/class', 'hideNode')
                          }
                        })
                      }
                      if(anV.emptyAnimation.realData.formulaType=='-') {
                        let tagArr = anV.emptyAnimation.realData.tag.split('-')
                        tagArr.forEach(tag=>{
                          if(item==tag) {
                            v.attr('svgele/style', 'animation-play-state:paused;')
                            v.attr('svgele/class', 'hideNode')
                          }
                        })
                      }
                    }
                  }
                  if(anV.animation=='PipelineFlow') {//流水动画
                    if(anV.pipelineFlow.startParameter){
                      if(anV.pipelineFlow.startParameter.dataType=='Bool') {
                        if(anV.pipelineFlow.startParameter.boolData.formulaType=='empty') {
                          if(item == anV.pipelineFlow.startParameter.boolData.tag) {
                            let itemVal = Number(wsdata.content[item]).toFixed(2)
                            let numTagVal= Number(anV.pipelineFlow.startParameter.boolData.tagValue)
                            let numItemVal= Number(itemVal)
                            if(numItemVal==numTagVal) {
                              waterLinearAnmi(v,wsdata.content)
                              v.attr('svgele/class', 'showNode')
                            }
                          }
                        }
                        if(anV.pipelineFlow.startParameter.boolData.formulaType=='&') {
                          let tagArr = anV.pipelineFlow.startParameter.boolData.tag.split('&')
                          tagArr.forEach((tag,tagIndex)=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              let numItemVal= Number(itemVal)
                              operationArr.push(numItemVal)
                              if(tagArr.length-1==tagIndex) {
                                let opResult = operationArr.every(op => { return op == anV.pipelineFlow.startParameter.boolData.tagValue})
                                if(opResult) {
                                  waterLinearAnmi(v,wsdata.content)
                                  v.attr('svgele/class', 'showNode')
                                  return
                                }
                              }
                            }
                          })
                        }
                        if(anV.pipelineFlow.startParameter.boolData.formulaType=='||') {
                          let tagArr = anV.pipelineFlow.startParameter.boolData.tag.split('||')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              let numItemVal= Number(itemVal)
                              if(numItemVal==anV.pipelineFlow.startParameter.boolData.tagValue) {
                                waterLinearAnmi(v,wsdata.content)
                                v.attr('svgele/class', 'showNode')
                                return
                              }
                            }
                          })
                        }
                        if(anV.pipelineFlow.startParameter.boolData.formulaType=='!') {

                        }
                      }
                      if(anV.pipelineFlow.startParameter.dataType=='Real') {
                        if(anV.pipelineFlow.startParameter.realData.formulaType=='empty') {
                          if(item == anV.pipelineFlow.startParameter.realData.tag) {
                            waterLinearAnmi(v,wsdata.content)
                            v.attr('svgele/class', 'showNode')
                          }
                        }
                        if(anV.pipelineFlow.startParameter.realData.formulaType=='+') {
                          let tagArr = anV.pipelineFlow.startParameter.realData.tag.split('+')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              waterLinearAnmi(v,wsdata.content)
                              v.attr('svgele/class', 'showNode')
                            }
                          })
                        }
                        if(anV.pipelineFlow.startParameter.realData.formulaType=='-') {
                          let tagArr = anV.pipelineFlow.startParameter.realData.tag.split('-')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              waterLinearAnmi(v,wsdata.content)
                              v.attr('svgele/class', 'showNode')
                            }
                          })
                        }
                      }
                    }
                    
                    if(anV.pipelineFlow.stopParameter){
                      if(anV.pipelineFlow.stopParameter.dataType=='Bool') {
                        if(anV.pipelineFlow.stopParameter.boolData.formulaType=='empty') {
                          if(item == anV.pipelineFlow.stopParameter.boolData.tag) {
                            let itemVal = Number(wsdata.content[item]).toFixed(2)
                            let numTagVal= Number(anV.pipelineFlow.stopParameter.boolData.tagValue)
                            let numItemVal= Number(itemVal)
                            if(numItemVal==numTagVal) {
                              if(anV.pipelineFlow.stopParameter.display=='hide') {
                                v.attr('svgele/style', 'animation-play-state:paused;')
                                v.attr('svgele/class', 'hideNode')
                              }
                              if(anV.pipelineFlow.stopParameter.display=='keep') {
                                v.attr('svgele/style', 'animation-play-state:paused;')
                                v.attr('svgele/class', 'showNode')
                              }
                            }
                          }
                        }
                        if(anV.pipelineFlow.stopParameter.boolData.formulaType=='&') {
                          let tagArr = anV.pipelineFlow.stopParameter.boolData.tag.split('&')
                          tagArr.forEach((tag,tagIndex)=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              let numItemVal= Number(itemVal)
                              operationArr.push(numItemVal)
                              if(tagArr.length-1==tagIndex) {
                                let opResult = operationArr.every(op => { return op == anV.pipelineFlow.stopParameter.boolData.tagValue})
                                if(opResult) {
                                  if(anV.pipelineFlow.stopParameter.display=='hide') {
                                    v.attr('svgele/style', 'animation-play-state:paused;')
                                    v.attr('svgele/class', 'hideNode')
                                  }
                                  if(anV.pipelineFlow.stopParameter.display=='keep') {
                                    v.attr('svgele/style', 'animation-play-state:paused;')
                                    v.attr('svgele/class', 'showNode')
                                  }
                                  return
                                }
                              }
                            }
                          })
                        }
                        if(anV.pipelineFlow.stopParameter.boolData.formulaType=='||') {
                          let tagArr = anV.pipelineFlow.stopParameter.boolData.tag.split('||')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              let numItemVal= Number(itemVal)
                              if(numItemVal==anV.pipelineFlow.stopParameter.boolData.tagValue) {
                                if(anV.pipelineFlow.stopParameter.display=='hide') {
                                  v.attr('svgele/style', 'animation-play-state:paused;')
                                  v.attr('svgele/class', 'hideNode')
                                }
                                if(anV.pipelineFlow.stopParameter.display=='keep') {
                                  v.attr('svgele/style', 'animation-play-state:paused;')
                                  v.attr('svgele/class', 'showNode')
                                }
                                return
                              }
                            }
                          })
                        }
                        if(anV.pipelineFlow.stopParameter.boolData.formulaType=='!') {

                        }
                      }
                      if(anV.pipelineFlow.stopParameter.dataType=='Real') {
                        if(anV.pipelineFlow.stopParameter.realData.formulaType=='empty') {
                          if(item == anV.pipelineFlow.stopParameter.realData.tag) {
                            if(anV.pipelineFlow.stopParameter.display=='hide') {
                              v.attr('svgele/style', 'animation-play-state:paused;')
                              v.attr('svgele/class', 'hideNode')
                            }
                            if(anV.pipelineFlow.stopParameter.display=='keep') {
                              v.attr('svgele/style', 'animation-play-state:paused;')
                              v.attr('svgele/class', 'showNode')
                            }
                          }
                        }
                        if(anV.pipelineFlow.stopParameter.realData.formulaType=='+') {
                          let tagArr = anV.pipelineFlow.stopParameter.realData.tag.split('+')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              if(anV.pipelineFlow.stopParameter.display=='hide') {
                                v.attr('svgele/style', 'animation-play-state:paused;')
                                v.attr('svgele/class', 'hideNode')
                              }
                              if(anV.pipelineFlow.stopParameter.display=='keep') {
                                v.attr('svgele/style', 'animation-play-state:paused;')
                                v.attr('svgele/class', 'showNode')
                              }
                            }
                          })
                        }
                        if(anV.pipelineFlow.stopParameter.realData.formulaType=='-') {
                          let tagArr = anV.pipelineFlow.stopParameter.realData.tag.split('-')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              if(anV.pipelineFlow.stopParameter.display=='hide') {
                                v.attr('svgele/style', 'animation-play-state:paused;')
                                v.attr('svgele/class', 'hideNode')
                              }
                              if(anV.pipelineFlow.stopParameter.display=='keep') {
                                v.attr('svgele/style', 'animation-play-state:paused;')
                                v.attr('svgele/class', 'showNode')
                              }
                            }
                          })
                        }
                      }
                    }
                  }
                  if(anV.animation=='waterLevel') {//普通节点液位动画
                    if(item == anV.waterLevel.tag) {
                      let numItemVal= Number(wsdata.content[item])
                      let maxVal= Number(anV.waterLevel.max)
                      let calculate = (numItemVal/maxVal)*100
                      //液位动画
                      waterLevel(v,calculate)
                      let foreignObject = createWaterSvg(editableTabsValue, v, item, numItemVal.toFixed(2))
                      // 通过class属性找到对应的元素标签
                      const container =  document.getElementById('containerDetailChart'+editableTabsValue)
                      const tagContent = container.querySelector('.water'+item+v.id)
                      // 获取文本父节点
                      const parentNode = tagContent.parentNode
                      const subForeignNode = parentNode.querySelector('foreignObject')
                      if(subForeignNode) {
                        parentNode.removeChild(subForeignNode)
                      }
                      parentNode.appendChild(foreignObject)
                    }
                  }
                  if(anV.animation=='textChange') {//文本变化
                    anV.textChange.dataParameters.forEach(textCV=>{
                      if(textCV.dataType=='Bool') {
                        if(textCV.boolData.formulaType=='empty') {
                          if(item == textCV.boolData.tag) {
                            let itemVal = Number(wsdata.content[item]).toFixed(2)
                            let numTagVal= Number(textCV.boolData.tagValue)
                            let numItemVal= Number(itemVal)
                            if(numItemVal==numTagVal) {
                              v.attr('label/text', textCV.boolData.tagValueDescribe)
                              v.attr('label/fill', textCV.textColor)
                              v.attr('body/class', 'hideNode')
                              if(textCV.display=='hide') {
                                v.attr('label/class', 'hideNode')
                              }
                              if(textCV.display=='keep') {
                                v.attr('label/class', 'showNode')
                              }
                            }
                          }
                        }
                        if(textCV.boolData.formulaType=='&') {
                          let tagArr = textCV.boolData.tag.split('&')
                          tagArr.forEach((tag,tagIndex)=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              let numItemVal= Number(itemVal)
                              operationArr.push(numItemVal)
                              if(tagArr.length-1==tagIndex) {
                                let opResult = operationArr.every(op => { return op == textCV.boolData.tagValue})
                                if(opResult) {
                                  v.attr('label/text', textCV.boolData.tagValueDescribe)
                                  v.attr('label/fill', textCV.textColor)
                                  v.attr('body/class', 'hideNode')
                                  if(textCV.display=='hide') {
                                    v.attr('label/class', 'hideNode')
                                  }
                                  if(textCV.display=='keep') {
                                    v.attr('label/class', 'showNode')
                                  }
                                  return
                                }
                              }
                            }
                          })
                        }
                        if(textCV.boolData.formulaType=='||') {
                          let tagArr = textCV.boolData.tag.split('||')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              let numItemVal= Number(itemVal)
                              if(numItemVal==textCV.boolData.tagValue) {
                                v.attr('label/text', textCV.boolData.tagValueDescribe)
                                v.attr('label/fill', textCV.textColor)
                                v.attr('body/class', 'hideNode')
                                if(textCV.display=='hide') {
                                  v.attr('label/class', 'hideNode')
                                }
                                if(textCV.display=='keep') {
                                  v.attr('label/class', 'showNode')
                                }
                                return
                              }
                            }
                          })
                        }
                        if(textCV.boolData.formulaType=='!') {

                        }
                      }
                      if(textCV.dataType=='Real') {
                        if(textCV.realData.formulaType=='empty') {
                          if(item == textCV.realData.tag) {
                            let itemVal = Number(wsdata.content[item]).toFixed(2)
                            v.attr('label/text', itemVal+' '+textCV.realData.unit)
                            v.attr('label/fill', textCV.textColor)
                            v.attr('body/class', 'hideNode')
                            if(textCV.display=='hide') {
                              v.attr('label/class', 'hideNode')
                            }
                            if(textCV.display=='keep') {
                              v.attr('label/class', 'showNode')
                            }
                          }
                        }
                        if(textCV.realData.formulaType=='+') {
                          let tagArr = textCV.realData.tag.split('+')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              v.attr('label/text', itemVal+' '+textCV.realData.unit)
                              v.attr('label/fill', textCV.textColor)
                              v.attr('body/class', 'hideNode')
                              if(textCV.display=='hide') {
                                v.attr('label/class', 'hideNode')
                              }
                              if(textCV.display=='keep') {
                                v.attr('label/class', 'showNode')
                              }
                            }
                          })
                        }
                        if(textCV.realData.formulaType=='-') {
                          let tagArr = textCV.realData.tag.split('-')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              v.attr('label/text', itemVal+' '+textCV.realData.unit)
                              v.attr('label/fill', textCV.textColor)
                              v.attr('body/class', 'hideNode')
                              if(textCV.display=='hide') {
                                v.attr('label/class', 'hideNode')
                              }
                              if(textCV.display=='keep') {
                                v.attr('label/class', 'showNode')
                              }
                            }
                          })
                        }
                      }
                    })
                  }
                  if(anV.animation=='background') {//背景颜色变化
                    anV.background.dataParameters.forEach(textCV=>{
                      if(textCV.dataType=='Bool') {
                        if(textCV.boolData.formulaType=='empty') {
                          if(item == textCV.boolData.tag) {
                            let itemVal = Number(wsdata.content[item]).toFixed(2)
                            let numTagVal= Number(textCV.boolData.tagValue)
                            let numItemVal= Number(itemVal)
                            if(numItemVal==numTagVal) {
                              if(textCV.backgroundType=='color') {
                                v.attr('body/stroke', 'none')
                                v.attr('body/fill', textCV.backgroundColor)
                              }
                            }
                          }
                        }
                        if(textCV.boolData.formulaType=='&') {
                          let tagArr = textCV.boolData.tag.split('&')
                          tagArr.forEach((tag,tagIndex)=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              let numItemVal= Number(itemVal)
                              operationArr.push(numItemVal)
                              if(tagArr.length-1==tagIndex) {
                                let opResult = operationArr.every(op => { return op == textCV.boolData.tagValue})
                                if(opResult) {
                                  if(textCV.backgroundType=='color') {
                                    v.attr('body/stroke', 'none')
                                    v.attr('body/fill', textCV.backgroundColor)
                                  }
                                  return
                                }
                              }
                            }
                          })
                        }
                        if(textCV.boolData.formulaType=='||') {
                          let tagArr = textCV.boolData.tag.split('||')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              let numItemVal= Number(itemVal)
                              if(numItemVal==textCV.boolData.tagValue) {
                                if(textCV.backgroundType=='color') {
                                  v.attr('body/stroke', 'none')
                                  v.attr('body/fill', textCV.backgroundColor)
                                }
                                return
                              }
                            }
                          })
                        }
                        if(textCV.boolData.formulaType=='!') {

                        }
                      }
                      if(textCV.dataType=='Real') {
                        if(textCV.realData.formulaType=='empty') {
                          if(item == textCV.realData.tag) {
                            let itemVal = Number(wsdata.content[item]).toFixed(2)
                            if(textCV.backgroundType=='color') {
                              v.attr('body/stroke', 'none')
                              v.attr('body/fill', textCV.backgroundColor)
                            }
                          }
                        }
                        if(textCV.realData.formulaType=='+') {
                          let tagArr = textCV.realData.tag.split('+')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              if(textCV.backgroundType=='color') {
                                v.attr('body/stroke', 'none')
                                v.attr('body/fill', textCV.backgroundColor)
                              }
                            }
                          })
                        }
                        if(textCV.realData.formulaType=='-') {
                          let tagArr = textCV.realData.tag.split('-')
                          tagArr.forEach(tag=>{
                            if(item==tag) {
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              if(textCV.backgroundType=='color') {
                                v.attr('body/stroke', 'none')
                                v.attr('body/fill', textCV.backgroundColor)
                              }
                            }
                          })
                        }
                      }
                    })
                  }
                })
              }
              if(v.store.data.data.graphical.textItems) {//文本节点textItems数据存在
                v.store.data.data.graphical.textItems.forEach(textItem=>{
                  if(textItem.boolDataContent) {//如果是Bool类型
                    if(textItem.boolDataContent.formulaType=='empty') {
                      if(item==textItem.boolDataContent.tag) {//推送tag和文本创建tag比较
                        for(let tagCode in textItem.boolDataContent.values) {
                          if(!isNaN(wsdata.content[item])) { //字符串为数字
                            // 处理websocket推送数据格式(1.000转换1.00)
                            let itemVal = Number(wsdata.content[item]).toFixed(2)
                            let numTagCode= Number(tagCode)
                            let numItemVal= Number(itemVal)
                            if(numItemVal==numTagCode) {//推送tag值和文本创建tag值比较
                              let foreignObject = createSvgTag(editableTabsValue, v, item, textItem.boolDataContent.values[tagCode])
                              // 通过class属性找到对应的元素标签
                              const container =  document.getElementById('containerDetailChart'+editableTabsValue)
                              const tagContent = container.querySelector('.t'+v.id)
                              // 获取文本父节点
                              const parentNode = tagContent.parentNode
                              const subForeignNode = parentNode.querySelector('foreignObject')
                              if(subForeignNode) {
                                parentNode.removeChild(subForeignNode)
                              }
                              parentNode.appendChild(foreignObject)
                            }
                          }else {
                            if(wsdata.content[item]==tagCode) {//推送tag值和文本创建tag值比较
                              let foreignObject = createSvgTag(editableTabsValue, v, item, textItem.boolDataContent.values[tagCode])
                              // 通过class属性找到对应的元素标签
                              const container =  document.getElementById('containerDetailChart'+editableTabsValue)
                              const tagContent = container.querySelector('.t'+v.id)
                              // 获取文本父节点
                              const parentNode = tagContent.parentNode
                              const subForeignNode = parentNode.querySelector('foreignObject')
                              if(subForeignNode) {
                                parentNode.removeChild(subForeignNode)
                              }
                              parentNode.appendChild(foreignObject)
                            }
                          }
                        }
                      }
                    }
                    if(textItem.boolDataContent.formulaType=='&') {
                      let tagArr = textItem.boolDataContent.tag.split('&')
                      tagArr.forEach((tag,tagIndex)=>{
                        if(item==tag) {
                          for(let tagCode in textItem.boolDataContent.values) {
                            if(!isNaN(wsdata.content[item])) { //字符串为数字
                              // 处理websocket推送数据格式(1.000转换1.00)
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              let numTagCode= Number(tagCode)
                              let numItemVal= Number(itemVal)
                              if(numItemVal==numTagCode) {//推送tag值和文本创建tag值比较
                                let foreignObject = createSvgTag(editableTabsValue, v, item, textItem.boolDataContent.values[tagCode])
                                // 通过class属性找到对应的元素标签
                                const container =  document.getElementById('containerDetailChart'+editableTabsValue)
                                const tagContent = container.querySelector('.t'+v.id)
                                // 获取文本父节点
                                const parentNode = tagContent.parentNode
                                const subForeignNode = parentNode.querySelector('foreignObject')
                                if(subForeignNode) {
                                  parentNode.removeChild(subForeignNode)
                                }
                                parentNode.appendChild(foreignObject)
                              }
                            }else {
                              if(wsdata.content[item]==tagCode) {//推送tag值和文本创建tag值比较
                                let foreignObject = createSvgTag(editableTabsValue, v, item, textItem.boolDataContent.values[tagCode])
                                // 通过class属性找到对应的元素标签
                                const container =  document.getElementById('containerDetailChart'+editableTabsValue)
                                const tagContent = container.querySelector('.t'+v.id)
                                // 获取文本父节点
                                const parentNode = tagContent.parentNode
                                const subForeignNode = parentNode.querySelector('foreignObject')
                                if(subForeignNode) {
                                  parentNode.removeChild(subForeignNode)
                                }
                                parentNode.appendChild(foreignObject)
                              }
                            }
                          }
                        }
                      })
                    }
                    if(textItem.boolDataContent.formulaType=='||') {
                      let tagArr = textItem.boolDataContent.tag.split('||')
                      tagArr.forEach((tag,tagIndex)=>{
                        if(item==tag) {
                          for(let tagCode in textItem.boolDataContent.values) {
                            if(!isNaN(wsdata.content[item])) { //字符串为数字
                              // 处理websocket推送数据格式(1.000转换1.00)
                              let itemVal = Number(wsdata.content[item]).toFixed(2)
                              let numTagCode= Number(tagCode)
                              let numItemVal= Number(itemVal)
                              if(numItemVal==numTagCode) {//推送tag值和文本创建tag值比较
                                let foreignObject = createSvgTag(editableTabsValue, v, item, textItem.boolDataContent.values[tagCode])
                                // 通过class属性找到对应的元素标签
                                const container =  document.getElementById('containerDetailChart'+editableTabsValue)
                                const tagContent = container.querySelector('.t'+v.id)
                                // 获取文本父节点
                                const parentNode = tagContent.parentNode
                                const subForeignNode = parentNode.querySelector('foreignObject')
                                if(subForeignNode) {
                                  parentNode.removeChild(subForeignNode)
                                }
                                parentNode.appendChild(foreignObject)
                              }
                            }else {
                              if(wsdata.content[item]==tagCode) {//推送tag值和文本创建tag值比较
                                let foreignObject = createSvgTag(editableTabsValue, v, item, textItem.boolDataContent.values[tagCode])
                                // 通过class属性找到对应的元素标签
                                const container =  document.getElementById('containerDetailChart'+editableTabsValue)
                                const tagContent = container.querySelector('.t'+v.id)
                                // 获取文本父节点
                                const parentNode = tagContent.parentNode
                                const subForeignNode = parentNode.querySelector('foreignObject')
                                if(subForeignNode) {
                                  parentNode.removeChild(subForeignNode)
                                }
                                parentNode.appendChild(foreignObject)
                              }
                            }
                          }
                        }
                      })
                    }
                    if(textItem.boolDataContent.formulaType=='!') {

                    }
                  }
                  if(textItem.realDataContent) {//如果是Real类型
                    if(textItem.realDataContent.formulaType=='empty') {
                      if(item==textItem.realDataContent.tag) {//推送tag和文本创建tag比较
                        // 处理websocket推送数据格式(1.000转换1.00)
                        let itemVal = Number(wsdata.content[item]).toFixed(2)
                        let foreignObject = createSvgTag(editableTabsValue, v, item, itemVal, textItem.realDataContent.unit)
                        // 通过class属性找到对应的元素标签
                        const container =  document.getElementById('containerDetailChart'+editableTabsValue)
                        const tagContent = container.querySelector('.t'+v.id)
                        // 获取文本父节点
                        const parentNode = tagContent.parentNode
                        const subForeignNode = parentNode.querySelector('foreignObject')
                        if(subForeignNode) {
                          parentNode.removeChild(subForeignNode)
                        }
                        parentNode.appendChild(foreignObject)
                      }
                    }
                    if(textItem.realDataContent.formulaType=='+') {
                      let tagArr = textItem.realDataContent.tag.split('+')
                      tagArr.forEach((tag,tagIndex)=>{
                        if(item==tag) {
                          // 处理websocket推送数据格式(1.000转换1.00)
                          let itemVal = Number(wsdata.content[item]).toFixed(2)
                          let foreignObject = createSvgTag(editableTabsValue, v, item, itemVal, textItem.realDataContent.unit)
                          // 通过class属性找到对应的元素标签
                          const container =  document.getElementById('containerDetailChart'+editableTabsValue)
                          const tagContent = container.querySelector('.t'+v.id)
                          // 获取文本父节点
                          const parentNode = tagContent.parentNode
                          const subForeignNode = parentNode.querySelector('foreignObject')
                          if(subForeignNode) {
                            parentNode.removeChild(subForeignNode)
                          }
                          parentNode.appendChild(foreignObject)
                        }
                      })
                    }
                    if(textItem.realDataContent.formulaType=='-') {
                      let tagArr = textItem.realDataContent.tag.split('-')
                      tagArr.forEach((tag,tagIndex)=>{
                        if(item==tag) {
                          // 处理websocket推送数据格式(1.000转换1.00)
                          let itemVal = Number(wsdata.content[item]).toFixed(2)
                          let foreignObject = createSvgTag(editableTabsValue, v, item, itemVal, textItem.realDataContent.unit)
                          // 通过class属性找到对应的元素标签
                          const container =  document.getElementById('containerDetailChart'+editableTabsValue)
                          const tagContent = container.querySelector('.t'+v.id)
                          // 获取文本父节点
                          const parentNode = tagContent.parentNode
                          const subForeignNode = parentNode.querySelector('foreignObject')
                          if(subForeignNode) {
                            parentNode.removeChild(subForeignNode)
                          }
                          parentNode.appendChild(foreignObject)
                        }
                      })
                    }
                  }
                })
              }
              if(v.store.data.data.graphicalType=='editText') {//编辑文本节点
                if(item==v.store.data.data.graphical.tag) {
                  let itemVal = Number(wsdata.content[item]).toFixed(2)
                  let foreignObject = editSvgTag(editableTabsValue, v, item, itemVal, v.store.data.data.graphical.unit)
                  // 通过class属性找到对应的元素标签
                  const container =  document.getElementById('containerDetailChart'+editableTabsValue)
                  const tagContent = container.querySelector('.t'+v.id)
                  // 获取文本父节点
                  const parentNode = tagContent.parentNode
                  const subForeignNode = parentNode.querySelector('foreignObject')
                  if(subForeignNode) {
                    parentNode.removeChild(subForeignNode)
                  }
                  parentNode.appendChild(foreignObject)
                }
              }
            }
          }
        }
      }else if(v.store.data.data&&v.store.data.data.withData==1) {
        if(v.store.data.data.graphical.customAnimations) {
          v.store.data.data.graphical.customAnimations.forEach(anV=>{
            if(anV.withoutData=='execute') {//默认执行动画
              // waterLinearAnmi(v)
              v.attr('svgele/class', 'showNode')
            }else if(anV.withoutData=='noExecute') {//默认不执行
              v.attr('svgele/class', 'hideNode')
            }
          })
        }
      }
    })
}